/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Global } from "@emotion/react";
import { useState } from "react";

import MenuItems from "./Components/MenuItems";
import MenuData from "./Components/MenuData";
import Navbar from "./Components/Navbar";
import React from "react";
import Firebase from "firebase";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';

import {
  Table,
  ButtonGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Button,
  InputGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Form,
  FormText,
} from "reactstrap";

const firebaseConfig = {
  apiKey: "AIzaSyDSaYuHlpFznfk_nhTcitcoGG-8QUGK1ec",
  authDomain: "onroad3-e593b.firebaseapp.com",
  databaseURL: "https://onroad3-e593b-default-rtdb.firebaseio.com",
  projectId: "onroad3-e593b",
  storageBucket: "onroad3-e593b.appspot.com",
  messagingSenderId: "587064425563",
  appId: "1:587064425563:web:99aac8f9a9a8b7cae9137e",
  measurementId: "G-5VFLZ6SK9T"
}
Firebase.initializeApp(firebaseConfig);

function App() {
  const [all, setAll] = useState(true);
  const [breakfast, setBreakfast] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [shakes, setShakes] = useState(false);
  const breakpoints = [576, 768, 992, 1200];

  const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);
  const { width } = useWindowSize();


const pathname = window.location.pathname
 let storeName = "";
 let urlOrderID = "";
 if(pathname == '/' || !pathname){
  window.location.replace('https://onroad.app');
 }else{
   let params = (new URL(document.location)).searchParams;
   urlOrderID = params.get("orderid");
   storeName = pathname.substring(1).split("-").join(" ").toUpperCase();
 }

  // baseList.forEach(item => {
  //   Firebase.database()
  //   .ref(`/ecommerce/storeuniqid/products/sampleproducts/`).push(item)
  // })
  
  const [list, setList] = React.useState();
  const [baseList, setBaseList] = React.useState();

  const [cart, setCart] = React.useState();
  const [input, setInput] = React.useState();
  const [cartView, setCartView] = React.useState(false);
  const [storeUiniqId, setStoreUiniqId] = React.useState();

  const [openedCollapses, setOpenCollapses] = React.useState(["collapseOne"]);
  const [hTabs, sethTabs] = React.useState("ht1");
  const [vTabs, setvTabs] = React.useState("vt1");
  const [vTabsIcons, setvTabsIcons] = React.useState("vti1");
  const [pageSubcategories, setpageSubcategories] = React.useState("ps1");
  const [modalClassic, setModalClassic] = React.useState(false);
  const [address, setAddress] = React.useState();
  const [addressLatLng, setAddressLatLng] = React.useState();
  const [addressLocation, setAddressLocation] = React.useState();
  const [phone, setPhone] = React.useState();
  const [name, setName] = React.useState();
  const [landMark, setLandMark] = React.useState();
  const [orderId, setOrderId] = React.useState();
  const [isError, setIsError] = React.useState();
  const [people, setPeople] = React.useState();
  const [paymentMethod, setPaymentMethod] = React.useState("cashondelivery");
  const [currentOffer, setCurrentOffer] = React.useState();

  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };

  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      .ref(`/pathtoid/${pathname.substring(1)}/`)
      .once('value', snapshot => {
        const data = snapshot.val()
        if (data) {
          setStoreUiniqId(data)
        }else{
          window.location.replace('https://onroad.app');
        }
      });
    return () => Firebase.database().ref(`/pathtoid/${pathname.substring(1)}/`).off('value', onChildAdd);
  }, [pathname])


  // http://localhost:3000/easy-take-store?orderid=-N-2AHsESjnn2WUKnCxV
  React.useEffect(() => {

      Firebase.database()
      .ref(`/foodorders/${storeUiniqId}/${urlOrderID}`)
      .once('value', snapshot => {
        const data = snapshot.val()
        // console.log("====data===", data)
        if (data) {
          setCart(data.cart)
          setCartView(true)
        }
      });
    // return () => Firebase.database().ref(`/ecommerce/orders/${storeUiniqId}/${urlOrderID}`).off('value', onChildAdd);
  }, [storeUiniqId && urlOrderID ])
  
  React.useEffect(() => {
    const onChildAdd = Firebase.database()
      // .ref(`/foodmenu/${storeUiniqId}/`)
      .ref("ecommerce/I9VhYOzLpAKQfeECLL7BeK7cxbUndR+RS+4Mw222RVM=")
      .on('value', snapshot => {
        const data = snapshot.val()
        if (data && data.products) {
          let result = []
          const keys = Object.keys(data.products);
          for (let index = keys.length - 1; index > -1; index--) {
            if(data.products[keys[index]] && data.products[keys[index]].name && data.products[keys[index]].price){
              const element = Object.assign(data.products[keys[index]], {id: keys[index]})
              result.push(element)
            }
          }
          setList(result)
        }
        if (data && data.people) {
          setPeople(data.people[Object.keys(data.people)[0]])
        }
        if (data && data.offers) {
          setCurrentOffer(data.offers[Object.keys(data.offers)[0]])
        }
        
        
      });
    return () => Firebase.database().ref(`/ecommerce/I9VhYOzLpAKQfeECLL7BeK7cxbUndR+RS+4Mw222RVM=/`).off('value', onChildAdd);
  }, [storeUiniqId])

  // if(Instamojo){
  //   Instamojo.configure({
  //     handlers: {
  //       onOpen: function () {
  //         console.log('its open');
  //       },
  //       onClose: function (data) {
  //         console.log('its closed', data);
  //       },
  //       onSuccess: function (response) {
  //         console.log('success response', response);
  //       },
  //       onFailure: function (response) {
  //         console.log('failure response', response);
  //       },
  //     },
  //   });
  // }
  const increaseQty = (product) => {
    if(cart && cart[product.id]){
      const productCount = Number(cart[product.id].split("||")[3].split(":")[1]) + 1
      const productCost = productCount * Number(cart[product.id].split("||")[2].split(":")[1])
      setCart(prevCart => ({
        ...prevCart,
        [product.id]: `name:${product.name}||id:${product.id}||price:${product.price}||quantity:${productCount}||totalCost:${productCost}||productimage:${product.image}`
    }));
    } else{
      setCart(prevCart => ({
        ...prevCart,
        [product.id]: `name:${product.name}||id:${product.id}||price:${product.price}||quantity:1||totalCost:${product.price}||productimage:${product.image}`
      }));
    }
  }

  const placeTheOrder = async () => {
    if(!name || !phone || !address || !cart){
      setIsError(true)
      return ;
    }
    setIsError(false)
    const orderRef = await Firebase.database()
      .ref(`/foodorders/${storeUiniqId}/`).push({
        name: name,
        phone: phone,
        address: address,
        addressLatLng: addressLatLng || "",
        addressLocation: addressLocation || "",
        landMark: landMark,
        cart: cart,
        payment: paymentMethod
      })
    const orderFBID = await orderRef.key;
    setOrderId(orderFBID);
    setCart(null)
      }
  
  const decreaseQty = (product) =>{
    if(cart[product.id] && Number(cart[product.id].split("||")[3].split(":")[1]) > 0) {
      let a = {}
      const productCount = Number(cart[product.id].split("||")[3].split(":")[1]) - 1
      const productCost = productCount *  Number(cart[product.id].split("||")[2].split(":")[1])
      // a[product.id] = `name:${product.name},id:${product.id},price:${product.price},quantity:${productCount},totalCost:${productCost}`
      // const t = Object.assign(cart, a)
      // setCart(t)
      setCart(prevCart => ({
        ...prevCart,
        [product.id]: `name:${product.name}||id:${product.id}||price:${product.price}||quantity:${productCount}||totalCost:${productCost}||productimage:${product.image}`
      }));
    }
  }

 
  const getCoordinates = (address) => {
    geocodeByAddress(address.value.description)
      .then(results => getLatLng(results[0]))
      .then((data) => {
        // console.log('LAT: ', data.lat);
        // console.log('LNG: ', data.lng);
        setAddress(address.value.description)
        if (address.value && address.value.structured_formatting && address.value.structured_formatting.secondary_text) {
          setAddressLocation(address.value.structured_formatting.secondary_text)
        }
        setAddressLatLng(data)
      })
  };
  const orderInputs = () => {
    return (
      <>
              <Row style={{width: "100%", paddingBottom: "10px"}}>
                <Label sm="3">Address</Label>
                <Col sm="9">
                  <FormGroup>
                    <GooglePlacesAutocomplete
                      selectProps={{
                      onChange: (address) => getCoordinates(address),
                    }}
                      placeholder="Search Address "
                      apiKey = "AIzaSyBqFuNz0gvqEjI0ymFGFX6r3Vwpir91dVk"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{width: "100%", paddingBottom: "10px"}}>
                <Label sm="3">Landmark</Label>
                <Col sm="9">
                  <FormGroup>
                    <Input defaultValue="" placeholder="Apartment/Block/PG/Flat" type="text" required onBlur={(e)=>{
                      setLandMark(e.target.value)
                    }}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{width: "100%", paddingBottom: "10px"}}>
                <Label sm="3">Phone</Label>
                  <Col sm="9">
                    <FormGroup>
                      <Input defaultValue="" placeholder="10 Digit phone number " type="text" required onBlur={(e)=>{
                      setPhone(e.target.value)
                    }}/>
                    </FormGroup>
                  </Col>
              </Row>
              <Row style={{width: "100%", paddingBottom: "10px"}}>
                <Label sm="3">Name</Label>
                <Col sm="9">
                  <FormGroup>
                    <Input defaultValue="" placeholder="Full name" type="text" required onBlur={(e)=>{
                      setName(e.target.value)
                    }}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{width: "100%", paddingBottom: "10px"}}>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      defaultChecked
                      defaultValue="option1"
                      id="exampleRadios1"
                      name="exampleRadios"
                      type="radio"
                      onClick={()=> {setPaymentMethod('cashondelivery')}}
                    />
                    <span className="form-check-sign" />
                    Cash On Delivery
                  </Label>
                </FormGroup>
                <FormGroup check className="form-check-radio">
                  <Label check>
                    <Input
                      defaultValue="option2"
                      id="exampleRadios2"
                      name="exampleRadios"
                      type="radio"
                      onClick={()=> {setPaymentMethod('phonepay')}}
                    />
                    <span className="form-check-sign" />
                    Already Paid With Phonepay
                  </Label>
                </FormGroup>
              </Row>
              <br />
              <b>Phonepay Number: {people && people.phone}</b>
      </>
    )
  }
  
  const orderCompleteSuccess = () => {
    return (
      <>
      Order ID 
      <br />
      <b>{orderId}</b>
      <br />
      Please share the Order ID with store owner for faster delivery
      <br />
      <a href={`http://order.onroad.app/${pathname}?orderid=${orderId}`}>{`http://order.onroad.app/${pathname}?orderid=${orderId}`}</a>
      <br />
      <p>Share with store owner whatsapp <a href={`https://api.whatsapp.com/send?phone=${people && people.phone}`}>Web</a> | <a href={`whatsapp://send?phone=${people && people.phone}`}>App</a></p>
      </>
    )
  }
  return (
    <>
       <Modal
            isOpen={modalClassic}
            className="text-center"
            closeButton={false}
          >
            <ModalHeader
              className="justify-content-center uppercase title"
              // toggle={toggleModalClassic}
              tag="h4"
            >
              <span style={{paddingRight: "100px"}}> {orderId ? "Success": "Complete The Order"} &nbsp;&nbsp;&nbsp;&nbsp; </span>
              <span style={{textAlign: "right", fontSize: "15px", color: "red"}} onClick={toggleModalClassic}>&nbsp;&nbsp;&nbsp;&nbsp;X</span>

              <br />
              {isError ? (<span style={{color: "red", fontSize: "10px"}}>Please Enter All Fields</span>) : ""}
            </ModalHeader>
            <ModalBody>
              {orderId ? orderCompleteSuccess() : orderInputs()}
              
            </ModalBody>
            <br />
            {orderId ? "" : (
            <ModalFooter className="justify-content-center">
              <Button color="primary" className="justify-content-center" onClick={() => placeTheOrder()}>Place The Order</Button>
            </ModalFooter>
            )}
        </Modal>
   
    <div
      className="App"
      css={css`
        background: #f0eff1;
        height: 100%;
        padding: 70px 0;
      `}
    >
      <h1 style={{textAlign: "center"}}>{storeName}</h1>
      {people && people.phone > 0 ? (
          <p style={{
            textAlign: "center"
            }}>{people.phone}</p>
        ) : ""}
      {/* <Navbar
        setAll={setAll}
        setBreakfast={setBreakfast}
        setLunch={setLunch}
        setShakes={setShakes}
      /> */}

      <MenuItems
        items={list}
        all={true}
        increaseQty={increaseQty}
        cart={cart}
        decreaseQty={decreaseQty}
      />

       {list && list.length > 0 && ( <div style={{ 
          position: "sticky",
          padding: "1rem",
          textAlign: "center",
          background: "#881010",
          bottom: "10px",
          justifyContent: "center",
          width: width < 1000 ? "50%" : "20%",
          left: width < 1000 ? "22%" : "40%",
          borderRadius: "20px"
      }}>
          <button style={{fontSize: "20px", border: "none", background: "inherit", color: "white"}} onClick={toggleModalClassic} disabled={!cart}>Checkout {cart && Object.entries(cart).length} { cart && `(${Object.entries(cart).reduce((p, v) => p + Number(v[1].split("||totalCost:")[1].split("||productimage:")[0]), 0)})`}</button>
        </div>)}

      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

          ::selection {
            background: #000;
            color: #f0eff1;
          }

          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: "Poppins", sans-serif;
            --webkit-tap-highlight-color: transparent;
          }

          body::-webkit-scrollbar {
            width: 12px; /* width of the entire scrollbar */
          }

          body::-webkit-scrollbar-track {
            background: #f0eff1; /* color of the tracking area */
          }

          body::-webkit-scrollbar-thumb {
            background-color: #444444; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: 3px solid #f0eff1; /* creates padding around scroll thumb */
          }

          body {
            background: #f0eff1;
          }
          .container {
            width: 80%;
            margin: auto;
          }

          ${mq[(0, 1)]} {
            .container {
              width: 90%;
              margin: auto;
            }
          }

         
        `}
      />
    </div>
    </>
  );
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default App;
