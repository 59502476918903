import { motion } from "framer-motion";
import imgAllMenu from "../img/undraw_barbecue.svg";
import React from "react";

const MenuAll = ({ all, items,  increaseQty, decreaseQty, cart}) => {
  const itemContainer = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const [count, setCount] = React.useState(0)
  
  const { width } = useWindowSize();

  return (
    <>
      {all &&
        items.map((item, i) => (
          <motion.div
            className="menu-items"
            key={item.id}
            variants={itemContainer}
            transition={{ delay: i * 0.2 }}
          >
            {width >= 768 ? (<img src={item.image} style={{borderRadius: "17px"}} alt="food burger" />) : null}

            <motion.div className="item-content">
              <motion.div className="item-title-box" style={{paddingTop: "10px"}}>
                {/* <img src={"https://imagej.net/images/baboon.jpg"} alt="food burger"  style={{paddingRight: "10px"}}/> */}
                {width < 768 ? (<img src={item.image} alt="food burger" style={{paddingRight: "5px", marginLeft: "0px", marginRight: "0px", borderRadius: "17px"}}/>) : null}

                <motion.div style={{width: "90%", paddingRight: "10px"}}>
                  <motion.h5 className="item-title" style={{wordBreak: "break-all"}}>{item.name}&nbsp;&nbsp;&nbsp;${item.price}</motion.h5>
                </motion.div>
                <motion.div style={{fontSize: "15px", display: "flex", height: "20px"}}>
                <motion.button  style={{fontSize: "20px", border: "none", background: "inherit", width: "50px", fontWeight: "bolder"}} onClick={()=> decreaseQty(item)}>-</motion.button>
                <motion.p  style={{fontSize: "18px", border: "none", background: "inherit"}}>
                  &nbsp;{(cart && cart[item.id]) ? `${cart[item.id].split("||")[3].split(":")[1]} ` : 0} &nbsp;</motion.p>
                <motion.button  style={{fontSize: "20px", border: "none", background: "inherit", width: "50px", fontWeight: "bolder"}} onClick={()=> increaseQty(item)}>+</motion.button>
                </motion.div>
                {/* <motion.h5 className="item-price"> + -</motion.h5> */}
                {/* <motion.h5 className="item-price">+</motion.h5>
                <motion.h5 className="item-price">-</motion.h5> */}
              </motion.div>
              <motion.p className="item-desc">{item.name}{item.name}{item.name}{item.name}{item.name}</motion.p>
              <br/>
            </motion.div>
          </motion.div>
        ))}
    </>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default MenuAll;
