/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { motion } from "framer-motion";

import AllItems from "./MenuAll";
import Breakfast from "./MenuBreakfast";
import Lunch from "./MenuLunch";
import Shakes from "./MenuShakes";
import { Spinner } from "reactstrap";

const MenuItems = ({ items, all, increaseQty, decreaseQty, cart }) => {
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };

  const breakpoints = [576, 768, 992, 1200];

  const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);
  const showSpinner = () => {
    return (
      <div>
  <Spinner
    color="primary"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="secondary"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="success"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="danger"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="warning"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="info"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="light"
    type="grow"
  >
    Loading...
  </Spinner>
  <Spinner
    color="dark"
    type="grow"
  >
    Loading...
  </Spinner>
</div>
    )
  }
  return (
    <motion.div
      className="MenuItems container"
      variants={container}
      initial="hidden"
      animate="visible"
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        ${mq[2]} {
          grid-template-columns: 1fr;
        }
        margin-top: 50px;
        padding: 40px 20px;
        background: #fff;
        border-radius: 50px;
        ${mq[(0,1)]} {
          padding: 40px 10px;
        }

        .menu-items {
          padding: 1rem 1.5rem;
          display: flex;
          border: #efefef 1px solid;
          border-top: none;
          ${mq[(0,1)]} {
            padding: 0px;
            display: grid;
            img {
              margin-bottom: 10px;
              max-width: 100px;
            }
          }

          &:last-child {
            border-bottom: none;
          }

          &:nth-child(odd) {
            border-left: none;
            ${mq[2]} {
              border-right: none;
            }
          }

          &:nth-child(even) {
            border-right: none;
            ${mq[2]} {
              border-left: none;
            }
          }

          .item-content {
            display: grid;
            padding: 0 1rem;
            ${mq[(0, 1)]} {
              padding: 0;
            }
            p {
              font-size: 0.8rem;
              ${mq[(0, 1)]} {
                font-size: 0.7rem;
              }
            }

            .item-title-box {
              display: flex;
              justify-content: space-between;

              .item-title,
              .item-price {
                font-size: 1rem;
                ${mq[(0, 1)]} {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }

        img {
          height: 85px;
          ${mq[(0, 1)]} {
            height: 75px;
            margin-left: 15px;
          }
          max-width: 150px;
          cursor: pointer;
        }
       
      `}
    > 
     { items && items.length > 0 ? (<AllItems all={all} items={items}
        increaseQty={increaseQty}
        decreaseQty={decreaseQty}
        cart={cart}
      />): showSpinner()}
    </motion.div>
  );
};

export default MenuItems;
